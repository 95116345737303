<template>
  <section>
    <div>
      <v-card>
        <v-card-title class="EoleBlueLighten">Choix d'importation de vos favoris</v-card-title>
        <v-card-text class="pa-4">
          <v-alert prominent dense type="info" color="EoleBlue" text icon="mdi-google-chrome">
            Vous pouvez importer vos favoris provenant de votre navigateur préféré. Choisissez le fichier d'export .html
            que vous avez généré et choisissez les favoris que vous souhaitez importer à Mes Services
            Assurance.</v-alert
          >
        </v-card-text>
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">Import de vos favoris</span>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-text class="pa-4 mt-4">
          <v-row class="justify-center">
            <v-col cols="12" md="6">
              <v-file-input
                accept=".html"
                v-model="files"
                color="EoleBlue"
                counter
                label="Sélectionner votre fichier "
                placeholder="Sélectionner votre fichier"
                outlined
                :show-size="1000"
              >
              </v-file-input
            ></v-col>
            <v-col cols="12" md="6">
              <v-btn :disabled="files === null" @click="read" color="EoleBlue" class="white--text align-center"
                >Importer mes favoris</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col cols="12">
          <v-dialog v-model="dialogDelete" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5"> <v-icon>mdi-delete</v-icon> Supression du Favoris</span>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <v-container>
                  <v-alert type="error" color="EoleError" dense prominent text
                    >Êtes-vous sûr de vouloir supprimer votre favoris ?</v-alert
                  >
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="EoleYellow" text @click="closeDelete">Annuler</v-btn>
                <v-btn class="white--text" color="EoleError" @click="deleteItemConfirm">Oui, confirmer</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-dialog v-model="dialogImport" persistent max-width="1000px">
            <v-card>
              <v-card-title>
                <span class="text-h5">
                  <v-icon>mdi-heart</v-icon> Importez vos favoris - ({{ this.websitesImport.length }} importable)</span
                >
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-5">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="websitesImport"
                  item-key="name"
                  show-select
                  class="elevation-1"
                >
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="EoleYellow" text @click="dialogImport = !dialogImport">Annuler</v-btn>
                <v-btn class="white--text" color="EoleGreen" @click="importSelectedFavorite"
                  ><v-icon left>mdi-download</v-icon> Importer mes favoris selectionnés</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row
        ><v-col cols="12" md="6">
          <h1 class="my-4">Mes favoris ({{ this.favoriteItems.length }})</h1> </v-col
        ><v-col cols="12" md="6">
          <div class="d-flex mb-6 justify-end">
            <div class="pa-2">
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon> mdi-heart-plus </v-icon>
                    <span class="ml-2">Ajouter un favoris</span>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-alert prominent color="EoleBlue" dense text type="info"
                      >En ajoutant un favoris à votre liste de favoris, vous pouvez lister vos extranets personnels pour
                      vous rediriger en un clic vers votre site préféré.</v-alert
                    >
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            prepend-inner-icon="mdi-cursor-text"
                            outlined
                            v-model="editedFavorite.name"
                            label="Nom du favoris"
                            hint="Le nom qui vous parle le plus pour votre favoris 😉"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            prepend-inner-icon="mdi-web"
                            outlined
                            hint="Lien vers le site internet, comme l'exemple ci-contre : https://example.com"
                            v-model="editedFavorite.url"
                            :value="editedFavorite.url + 'toto'"
                            label="Url du favoris"
                            :prefix="editedIndex === -1 ? 'https://' : ''"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" text @click="close">Annuler</v-btn>
                    <v-btn
                      class="white--text"
                      color="EoleBlue"
                      @click="editedIndex === -1 ? save(editedFavorite.id) : save(editedFavorite.id)"
                      >{{ editedIndex === -1 ? "Ajouter" : "Modifier" }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <div class="pa-2">
              <v-menu class="pa-2" bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="EoleBlue" text v-bind="attrs" v-on="on">
                    trier ({{ sortInformation }})
                    <v-icon right>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="sort('ascending')" link>
                    <v-list-item-icon class="me-2">
                      <v-icon> mdi-order-alphabetical-ascending</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Classer par ordre alphabétique A-Z</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="sort('descending')" link>
                    <v-list-item-icon class="me-2">
                      <v-icon> mdi-order-alphabetical-descending</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Classer par ordre alphabétique Z-A</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="sort()" link>
                    <v-list-item-icon class="me-2">
                      <v-icon> mdi-calendar</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Classer par ordre d'ajout (défaut)</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-col></v-row
      >

      <v-row v-if="fetchData === true">
        <v-row>
          <v-col
            v-for="favorite in favoriteItems"
            :key="favorite.id"
            class="text-center d-flex"
            cols="12"
            md="3"
            sm="2"
          >
            <v-card
              style="border-color: #001f47"
              outlined
              flat
              :href="favorite.url"
              target="_blank"
              class="favorite-card rounded-xl flex d-flex flex-column justify-center mr-2"
            >
              <v-card-text class="services-font"
                ><v-img
                  class="mx-auto my-2"
                  max-width="50px"
                  :src="`https://logo.clearbit.com/${favorite.url.split('/')[2]}?size=128`"
                >
                </v-img>
                {{ favorite.name.toUpperCase() }}
              </v-card-text>
            </v-card>

            <v-btn fab small style="left: -40px" @click="editItem(favorite)">
              <v-icon color="EoleBlue"> mdi-heart-cog</v-icon>
            </v-btn>
            <v-btn fab small style="top: 50px; left: -80px" @click="deleteItem(favorite.id)">
              <v-icon color="error"> mdi-heart-minus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
      <v-row v-else-if="fetchData === false">
        <v-col cols="12" md="10">
          <v-alert color="EoleBlue" prominent icon="mdi-heart" dense border="left" text type="error" class="mb-0">
            <v-row align="center">
              <v-col class="grow"> Vous n'avez pas de favoris pour le moment. </v-col>
              <v-col class="shrink">
                <v-btn
                  class="mx-2"
                  dark
                  @click="
                    () => {
                      dialog = true
                    }
                  "
                >
                  <v-icon left> mdi-heart-plus </v-icon>
                  Ajouter mon premier favoris
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-divider class="my-10"></v-divider>
    </div>
  </section>
</template>
<script>
import config from "@/views/config/config"
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"

pdfMake.vfs = pdfFonts.pdfMake.vfs
const avatars = [
  "?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelGreen&clotheType=ShirtScoopNeck&eyeType=Wink&eyebrowType=UnibrowNatural&facialHairColor=Black&facialHairType=MoustacheMagnum&hairColor=Platinum&mouthType=Concerned&skinColor=Tanned&topType=Turban",
  "?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Gray02&clotheType=ShirtScoopNeck&eyeType=EyeRoll&eyebrowType=RaisedExcited&facialHairColor=Red&facialHairType=BeardMagestic&hairColor=Red&hatColor=White&mouthType=Twinkle&skinColor=DarkBrown&topType=LongHairBun",
  "?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Black&clotheType=ShirtVNeck&eyeType=Surprised&eyebrowType=Angry&facialHairColor=Blonde&facialHairType=Blank&hairColor=Blonde&hatColor=PastelOrange&mouthType=Smile&skinColor=Black&topType=LongHairNotTooLong",
  "?accessoriesType=Round&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=Overall&eyeType=Close&eyebrowType=AngryNatural&facialHairColor=Blonde&facialHairType=Blank&graphicType=Pizza&hairColor=Black&hatColor=PastelBlue&mouthType=Serious&skinColor=Light&topType=LongHairBigHair",
  "?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly",
]

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
export default {
  data: () => ({
    validityFormInputFile: true,
    importFavoriteRule: [
      v => !!v || "Le champ est requis",
      // verify if is .html
      v => {
        if (v) {
          const extension = v.split(".").pop()
          return extension === "html" || "Le fichier doit être au format .html"
        }
      },
    ],
    websitesImport: [],
    files: null,
    /* test purpose*/
    interlocutors: [
      {
        id: 1,
        firstname: "Roman",
        lastname: "Frichot",
        phone: "0611179108",
      },
    ],
    selected: [],
    resultFile: null,
    active: [],
    avatar: null,
    open: [],
    users: [],
    /* end test purpose */
    sortInformation: "défaut",
    favoriteId: null,
    editedIndex: -1,
    dialogDelete: false,
    dialog: false,
    editedFavorite: {
      id: null,
      name: "",
      url: "",
    },
    dialogImport: false,
    dataaaaa: [],
    defaultItem: {
      id: null,
      name: "",
      url: "",
    },
    headers: [
      {
        text: "Nom du favoris",
        align: "start",
        sortable: false,
        value: "name",
      },
    ],
    fetchData: false,
    isSearch: false,
    loading: false,
    search: null,
    select: null,
    services: [],
    panel: [0],
    favoriteItems: [],

    itemss: [],
    drawer: true,
    breadcrumbsItems: [
      {
        text: "Accueil",
        disabled: false,
        href: "/",
      },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau Favoris" : "Modification pour " + this.editedFavorite.name
    },
    items() {
      return [
        {
          name: "Mes extranets",
          children: this.favoriteItems,
        },
      ]
    },
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]

      return this.favoriteItems.find(user => user.id === id)
    },
  },

  watch: {
    selected: "randomAvatar",

    search(val) {
      val && val !== this.select && this.querySelections(val)
      this.loading = true
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  created() {
    let userID = localStorage.getItem("user_id")
    this.fetchExtranet(userID)
    /// Trier par ordre alphabétique par défaut
    this.sort("ascending")
  },
  methods: {
    validateFileInput() {
      if (this.$refs.formInputFile.validate()) {
        console.log("ok")
      }
    },
    getDataFavoriteExport() {
      console.log(toString(this.resultFile))
    },
    importSelectedFavorite() {
      let userID = localStorage.getItem("user_id")

      this.selected.forEach(element => {
        this.post({
          name: element.name,
          url: element.url,
        })
      })

      this.$toast.success(
        `Vous avez importé ${this.selected.length} favoris à votre liste de favoris Mes Services Assurance.`,
        {
          position: "bottom-right",
          timeout: 3000,
        },
      )
      this.dialogImport = false
      this.fetchExtranet(userID)
    },
    read() {
      this.websitesImport = []

      if (this.files != null) {
        const readerFile = new FileReader()
        readerFile.onload = el => {
          // read all lines and get link with "HREF" and put in array
          let lines = el.target.result.split("\n")
          for (let i = 0; i < lines.length; i++) {
            if (lines[i].includes("HREF")) {
              // get the link in line and put in array
              let link = lines[i].split('"')[1]
              this.websitesImport.push({
                // get the domain name of the link without www and .com or .fr
                name: link.split("/")[2],
                url: link,
              })
            }
          }
        }
        readerFile.readAsText(this.files)

        this.dialogImport = true
      } else {
        this.$toast.error(`Vous n'avez pas sélectionné de fichier.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchUsers(item) {
      // Remove in 6 months and say
      // you've made optimizations! :)
      await pause(1500)

      return fetch("https://jsonplaceholder.typicode.com/users")
        .then(res => res.json())
        .then(json => item.children.push(...json))
        .catch(err => console.warn(err))
    },
    randomAvatar() {
      this.avatar = avatars[Math.floor(Math.random() * avatars.length)]
    },
    sort(value) {
      switch (value) {
        case "ascending":
          this.favoriteItems.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
          this.sortInformation = "A-Z"
          break
        case "descending":
          this.favoriteItems.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1))
          this.sortInformation = "Z-A"
          break
        default:
          this.favoriteItems.sort((a, b) => (a.id > b.id ? 1 : -1))
          this.sortInformation = "défaut"
          break
      }
    },
    async putFavorite(idFavorite, data) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response
        if (localStorage.getItem("linked") != null) {
          response = await fetch(
            `${config.apiUri}/php/pages/favorites.php?id=${idFavorite}&name=${data.newName}&url=${data.newUrl}&linked=true`,
            {
              mode: "cors",
              method: "PUT",
              headers: headers,
              body: JSON.stringify(data),
            },
          )
        } else {
          response = await fetch(
            `${config.apiUri}/php/pages/favorites.php?id=${idFavorite}&name=${data.newName}&url=${data.newUrl}`,
            {
              mode: "cors",
              method: "PUT",
              headers: headers,
              body: JSON.stringify(data),
            },
          )
        }

        this.$toast.info(`Votre favoris à été modifié avec succès.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le favoris`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    editItem(item) {
      this.editedIndex = this.favoriteItems.indexOf(item)
      this.editedFavorite = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.favoriteId = item
      this.editedIndex = this.favoriteItems.findIndex(object => {
        return object.id === item
      })
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.favoriteItems.splice(this.editedIndex, 1)
      this.delete(this.favoriteId)
      this.closeDelete()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedIndex = -1
      })
    },

    async delete(favoriteID) {
      let userID = localStorage.getItem("user_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response
        if (localStorage.getItem("linked") != null) {
          response = await fetch(
            `${config.apiUri}/php/pages/favorites.php?idAccount=${userID}&favoriteId=${favoriteID}`,
            {
              mode: "cors",
              method: "DELETE",
              headers: headers,
            },
          )
        } else {
          response = await fetch(`${config.apiUri}/php/pages/favorites.php?id=${userID}&favoriteId=${favoriteID}`, {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          })
        }

        this.fetchExtranet(userID)
        this.$toast.success(`Vous avez supprimé votre favoris`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le favoris`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async post(data) {
      this.editedFavorite = {
        id: null,
        name: "",
        url: "",
      }

      let userID = localStorage.getItem("user_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response
        if (localStorage.getItem("linked") != null) {
          response = await fetch(
            `${config.apiUri}/php/pages/favorites.php?idAccount=${userID}&name=${data.name}&url=${data.url}`,
            {
              mode: "cors",
              method: "POST",
              headers: headers,
              body: JSON.stringify(data),
            },
          )
        } else {
          response = await fetch(
            `${config.apiUri}/php/pages/favorites.php?id=${userID}&name=${data.name}&url=${data.url}`,
            {
              mode: "cors",
              method: "POST",
              headers: headers,
              body: JSON.stringify(data),
            },
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    save(idFavorite) {
      const userID = localStorage.getItem("user_id")

      if (this.editedIndex > -1) {
        Object.assign(this.favoriteItems[this.editedIndex], this.editedFavorite)
        this.putFavorite(idFavorite, {
          newName: this.editedFavorite.name,
          newUrl: this.editedFavorite.url,
        })
      } else {
        this.favoriteItems.push(this.editedFavorite)
        this.post({ name: this.editedFavorite.name, url: "https://" + this.editedFavorite.url })

        this.$toast.success(`Vous avez ajouté votre favoris`, {
          position: "bottom-right",
          timeout: 3000,
        })
        this.fetchExtranet(userID)
      }

      this.close()
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedFavorite = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async fetchExtranet(userID) {
      this.favoriteItems = []
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response
        if (localStorage.getItem("linked") != null) {
          //   response = await fetch(`${config.apiUri}/accounts/${userID}/favorites`, {
          //  response = await fetch(`${config.apiUri}/php/pages/favorites.php?idAccount=${userID}`, {
          response = await fetch(`${config.apiUri}/accounts/${userID}/favorites`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        } else {
          response = await fetch(`${config.apiUri}/users/${userID}/favorites`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
        }

        const data = await response.json()

        if (data.length === 0) {
          this.fetchData = false
        } else {
          data.forEach(element => {
            this.favoriteItems.push({
              id: element.favorite_id,
              name: element.name,
              url: element.url,
            })
          })

          this.fetchData = true
        }
      } catch (e) {
        console.log(e)
        this.fetchData = false
      }
    },
    querySelections(v) {
      this.loading = true
      this.isSearch = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.items.filter(e => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },
  },
  mounted() {},
  beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.services {
  //background-color: #001f47;
  background-size: cover;
  padding: 5.5rem;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .services {
    padding: 1.5rem !important;
  }

  .favorite-card {
    width: 100% !important;
  }
}

.greeting-card {
  position: relative;

  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }

    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
